@import "variables";

.scroll-indicator {
    color: $colorBase;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    animation:
        scroll-indicator-fade-in 600ms cubic-bezier(0.19, 1, 0.22, 1),
        float 2s ease-in-out infinite alternate 600ms;
    user-select: none;
    cursor: default;
}

.scrolled .scroll-indicator {
    display: none;
}

.m-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.link {
    color: $colorTextPrimary;

    @media (prefers-color-scheme: dark) {
        color: $colorTextPrimaryDark;
    }
}

.link:hover,
.link:focus {
    text-decoration: none;
}

@each $selector, $color in $brandColors {
    .link-#{$selector}:hover,
    .link-#{$selector}:focus {
        color: $color;
    }
}

.text-spinner {
    font-family: monospace;
}

.scrumplex-logo {
    border-radius: 50%;
    box-shadow: 0 5px 36px transparentize($colorBrand, 0.3);
    margin-bottom: 10px;
    transition: transform 1000ms cubic-bezier(0.77, 0, 0.175, 1);

    @media (prefers-color-scheme: dark) {
        box-shadow: 0 5px 30px transparentize($colorBrand, 0.6);
    }
}

.wavy {
    background-image: url("../img/wavy.svg");
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center;

    @media (prefers-color-scheme: dark) {
        background-image: url("../img/wavy-dark.svg");
    }
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-justify-between {
    justify-content: space-between;
}

.flex-align-center {
    align-items: center;
}

.flex-grow {
    flex-grow: 1;
}

input {
    background-color: transparent;
    border: none;
    color: $colorTextPrimary;
    border-bottom: 2px solid $colorTextPrimary;
    outline: none;
    font-size: 1rem;
    padding: 4px 8px;
    margin: 8px 4px;
    border-top-left-radius: 8px;

    transition:
        background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
        border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    @media (prefers-color-scheme: dark) {
        color: $colorTextPrimaryDark;
        border-bottom-color: $colorTextPrimaryDark;
    }
}

input:hover,
input:focus {
    background-color: transparentize($colorTextPrimary, 0.95);

    @media (prefers-color-scheme: dark) {
        background-color: transparentize($colorTextPrimaryDark, 0.95);
    }
}

input:active {
    background-color: transparentize($colorTextPrimary, 0.8);

    @media (prefers-color-scheme: dark) {
        background-color: transparentize($colorTextPrimaryDark, 0.8);
    }
}

input:focus {
    outline: none;
    border-bottom-color: $colorBrand;
}

#ovenplayer {
    --op-accent-color: #{$colorBrand};
}

.theater {
    background-color: $colorBaseDark;

    .sheet::after,
    .sheet::before {
        display: none;
    }

    .wrapper {
        max-width: initial;
        min-height: initial;
    }

    #ovenplayer {
        max-height: 70vh;
    }
}
