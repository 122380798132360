@import "variables";

@keyframes panel-fade-in {
    from {
        opacity: 0;
        transform: translateY(100vh);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes panel-splash-fade-in {
    from {
        opacity: 0;
        transform: translateY(100vh);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (min-width: 768px) {
    @keyframes panel-fade-in {
        from {
            opacity: 0;
            transform: translateY(300px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes panel-splash-fade-in {
        from {
            opacity: 0;
            transform: translateY(50%);
        }

        to {
            opacity: 1;
            transform: translateY(-50%);
        }
    }
}

@keyframes panel-before-fade-in {
    from {
        background-position-x: -$longShadowLength;
    }

    to {
        background-position-x: 0;
    }
}

@keyframes panel-after-fade-in {
    from {
        background-position-y: -$longShadowLength;
    }

    to {
        background-position-y: 0;
    }
}

@keyframes panel-highlight {
    from {
        color: $colorBrand;
    }

    to {
        color: unset;
    }
}

@keyframes scroll-indicator-fade-in {
    from {
        transform: translateY(-50vh);
        opacity: 0;
    }

    to {
        transform: translateY(-10%);
        opacity: 1;
    }
}

@keyframes float {
    from {
        transform: translateY(-10%);
    }

    to {
        transform: translateY(10%);
    }
}
