@import "variables";

.btn {
    display: inline-block;
    min-width: 64px;
    margin: 4px;
    padding: 0 20px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    line-height: 2.25em;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    transition:
        background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
        box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    @include alt-font;
}

@each $name, $color in $btnColors {
    .btn.btn-#{$name},
    .btn.btn-#{$name}:focus {
        color: $color;
    }

    .btn.btn-#{$name}:hover {
        background-color: transparentize($color, 0.9);
        text-decoration: none;
    }

    .btn.btn-#{$name}:focus {
        background-color: transparentize($color, 0.95);
    }

    .btn.btn-#{$name}:active {
        background-color: transparentize($color, 0.7);
    }
}

.btn:focus {
    outline: none;
}
