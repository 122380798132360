/*!
 *    Personal website of Sefa Eyeoglu
 *    Copyright (C) 2018-2022  Sefa Eyeoglu <contact@scrumplex.net>
 *
 *    This program is free software: you can redistribute it and/or modify
 *    it under the terms of the GNU Affero General Public License as published by
 *    the Free Software Foundation, either version 3 of the License, or
 *    (at your option) any later version.
 *
 *    This program is distributed in the hope that it will be useful,
 *    but WITHOUT ANY WARRANTY; without even the implied warranty of
 *    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *    GNU Affero General Public License for more details.
 *
 *    You should have received a copy of the GNU Affero General Public License
 *    along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
@font-face {
  font-family: icons;
  src: url("icons.fb1c0f03.eot#iefix") format("embedded-opentype"), url("icons.ef141b08.woff2") format("woff2"), url("icons.f024abdc.woff") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  font-family: icons !important;
  font-weight: normal !important;
}

.icon-00-baseline:before {
  content: "";
}

.icon-codeberg:before {
  content: "";
}

.icon-github_sponsors:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-gitlab:before {
  content: "";
}

.icon-ko-fi:before {
  content: "";
}

.icon-liberapay:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-matrix:before {
  content: "";
}

.icon-new_tab:before {
  content: "";
}

.icon-paypal:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-teamspeak:before {
  content: "";
}

.icon-telegram:before {
  content: "";
}

.icon-wire:before {
  content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}

a:active, a:hover {
  outline: 0;
  text-decoration: underline;
}

b, strong {
  font-weight: bold;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
  src: url("fira-sans-cyrillic-400-normal.44d68a3d.woff2") format("woff2"), url("fira-sans-cyrillic-400-normal.5e8abce2.woff") format("woff");
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+460-52F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  src: url("fira-sans-cyrillic-ext-400-normal.7b2216d5.woff2") format("woff2"), url("fira-sans-cyrillic-ext-400-normal.f8dffdad.woff") format("woff");
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
  src: url("fira-sans-greek-400-normal.c922ce31.woff2") format("woff2"), url("fira-sans-greek-400-normal.18962478.woff") format("woff");
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+1F??;
  src: url("fira-sans-greek-ext-400-normal.85911ac3.woff2") format("woff2"), url("fira-sans-greek-ext-400-normal.e0f9e740.woff") format("woff");
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  src: url("fira-sans-latin-400-normal.045851ea.woff2") format("woff2"), url("fira-sans-latin-400-normal.277eaf91.woff") format("woff");
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  src: url("fira-sans-latin-ext-400-normal.b9ab556c.woff2") format("woff2"), url("fira-sans-latin-ext-400-normal.afa8879a.woff") format("woff");
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
  src: url("fira-sans-vietnamese-400-normal.39cc4ff9.woff2") format("woff2"), url("fira-sans-vietnamese-400-normal.363a2ba2.woff") format("woff");
}

@font-face {
  font-family: Josefin Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  src: url("josefin-sans-latin-400-normal.199aab13.woff2") format("woff2"), url("josefin-sans-latin-400-normal.0f549866.woff") format("woff");
}

@font-face {
  font-family: Josefin Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  src: url("josefin-sans-latin-ext-400-normal.6d6869ea.woff2") format("woff2"), url("josefin-sans-latin-ext-400-normal.61e0cfe1.woff") format("woff");
}

@font-face {
  font-family: Josefin Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
  src: url("josefin-sans-vietnamese-400-normal.48ed5a3d.woff2") format("woff2"), url("josefin-sans-vietnamese-400-normal.840dc60f.woff") format("woff");
}

body {
  color: #000;
  font-family: Fira Sans, Helvetica Neue, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.375;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fafafa;
  }
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
  overflow-wrap: break-word;
}

.text-right {
  text-align: right;
}

h1, h2, h3, h4, h5, blockquote, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-family: Josefin Sans, Fira Sans, Helvetica Neue, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5, blockquote {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

blockquote {
  margin-left: 0;
  margin-right: 0;
}

a {
  color: #d63333;
}

.icon-2x {
  font-size: 2em;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1300px) {
  .container {
    max-width: 1300px;
  }
}

.row {
  flex-wrap: wrap;
  display: flex;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-1:not(:last-child) {
  padding-right: 16px;
}

.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.col-2:not(:last-child) {
  padding-right: 16px;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-3:not(:last-child) {
  padding-right: 16px;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-4:not(:last-child) {
  padding-right: 16px;
}

.col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.col-5:not(:last-child) {
  padding-right: 16px;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-6:not(:last-child) {
  padding-right: 16px;
}

.col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.col-7:not(:last-child) {
  padding-right: 16px;
}

.col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.col-8:not(:last-child) {
  padding-right: 16px;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-9:not(:last-child) {
  padding-right: 16px;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-10:not(:last-child) {
  padding-right: 16px;
}

.col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.col-11:not(:last-child) {
  padding-right: 16px;
}

.col-12, .col, .col-big-12, .col-big-11, .col-big-10, .col-big-9, .col-big-8, .col-big-7, .col-big-6, .col-big-5, .col-big-4, .col-big-3, .col-big-2, .col-big-1, .col-med-12, .col-med-11, .col-med-10, .col-med-9, .col-med-8, .col-med-7, .col-med-6, .col-med-5, .col-med-4, .col-med-3, .col-med-2, .col-med-1, .col-smol-12, .col-smol-11, .col-smol-10, .col-smol-9, .col-smol-8, .col-smol-7, .col-smol-6, .col-smol-5, .col-smol-4, .col-smol-3, .col-smol-2, .col-smol-1, .col-tiny-12, .col-tiny-11, .col-tiny-10, .col-tiny-9, .col-tiny-8, .col-tiny-7, .col-tiny-6, .col-tiny-5, .col-tiny-4, .col-tiny-3, .col-tiny-2, .col-tiny-1 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (width >= 576px) {
  .col-tiny-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-tiny-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-tiny-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-tiny-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-tiny-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-tiny-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-tiny-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-tiny-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-tiny-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-tiny-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-tiny-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-tiny-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 768px) {
  .col-smol-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-smol-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-smol-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-smol-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-smol-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-smol-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-smol-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-smol-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-smol-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-smol-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-smol-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-smol-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 992px) {
  .col-med-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-med-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-med-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-med-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-med-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-med-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-med-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-med-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-med-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-med-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-med-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-med-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 1300px) {
  .col-big-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-big-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-big-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-big-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-big-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-big-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-big-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-big-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-big-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-big-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-big-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-big-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

body {
  background-color: #d63333;
  overflow-x: hidden;
}

.wrapper {
  min-height: 150vh;
  padding-bottom: 400px;
  position: relative;
}

.sheet {
  background-color: #fafafa;
  width: 100%;
  margin-bottom: 10px;
  padding: 24px 24px 8px;
  animation: .6s cubic-bezier(.23, 1, .32, 1) panel-fade-in;
  position: relative;
  box-shadow: 0 10px 20px #00000030, 0 6px 6px #0000003b;
}

@media (prefers-color-scheme: dark) {
  .sheet {
    background-color: #1a1a1a;
  }
}

.sheet:target {
  animation: .6s cubic-bezier(.23, 1, .32, 1) panel-fade-in, 2s linear .6s panel-highlight;
}

.sheet.sheet-splash, .sheet.sheet-splashed {
  animation-name: panel-splash-fade-in;
}

@media screen and (width >= 768px) {
  .sheet {
    box-shadow: none;
    border-top-left-radius: 32px;
  }

  .sheet.sheet-splash {
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
  }

  .sheet:before {
    content: "";
    transform-origin: 0 0;
    background: linear-gradient(to right, #481111, #0000) no-repeat;
    width: 200px;
    height: 100%;
    animation: .9s cubic-bezier(.23, 1, .32, 1) panel-before-fade-in;
    position: absolute;
    top: 0;
    left: 100%;
    transform: skewY(45deg);
  }

  .sheet:after {
    content: "";
    transform-origin: 0 0;
    background: linear-gradient(#481111, #0000) no-repeat;
    width: 100%;
    height: 200px;
    animation: .9s cubic-bezier(.23, 1, .32, 1) panel-after-fade-in;
    position: absolute;
    top: 100%;
    left: 0;
    transform: skewX(45deg);
  }
}

.scrolled .sheet[hidden] {
  opacity: 0;
  animation: none;
  display: block;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
}

#particles-js > canvas {
  z-index: -10;
}

#particles-js > .particle-gradient {
  background: linear-gradient(#d6333300 0%, #d63333 100%);
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: 0;
}

.btn {
  text-transform: uppercase;
  text-align: center;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  outline: none;
  min-width: 64px;
  margin: 4px;
  padding: 0 20px;
  font-family: Josefin Sans, Fira Sans, Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.25em;
  transition: background .3s cubic-bezier(.215, .61, .355, 1), box-shadow .3s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
}

.btn.btn-default, .btn.btn-default:focus {
  color: #000;
}

.btn.btn-default:hover {
  background-color: #0000001a;
  text-decoration: none;
}

.btn.btn-default:focus {
  background-color: #0000000d;
}

.btn.btn-default:active {
  background-color: #0000004d;
}

.btn.btn-primary, .btn.btn-primary:focus {
  color: #d63333;
}

.btn.btn-primary:hover {
  background-color: #d633331a;
  text-decoration: none;
}

.btn.btn-primary:focus {
  background-color: #d633330d;
}

.btn.btn-primary:active {
  background-color: #d633334d;
}

.btn.btn-accent, .btn.btn-accent:focus {
  color: #00bcd4;
}

.btn.btn-accent:hover {
  background-color: #00bcd41a;
  text-decoration: none;
}

.btn.btn-accent:focus {
  background-color: #00bcd40d;
}

.btn.btn-accent:active {
  background-color: #00bcd44d;
}

.btn:focus {
  outline: none;
}

.scroll-indicator {
  color: #fafafa;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  animation: .6s cubic-bezier(.19, 1, .22, 1) scroll-indicator-fade-in, 2s ease-in-out .6s infinite alternate float;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.scrolled .scroll-indicator {
  display: none;
}

.m-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.link {
  color: #000;
}

@media (prefers-color-scheme: dark) {
  .link {
    color: #fafafa;
  }
}

.link:hover, .link:focus {
  text-decoration: none;
}

.link-archlinux:hover, .link-archlinux:focus {
  color: #1793d1;
}

.link-bitcoin:hover, .link-bitcoin:focus {
  color: #f90;
}

.link-codeberg:hover, .link-codeberg:focus {
  color: #2185d0;
}

.link-github:hover, .link-github:focus {
  color: #555;
}

.link-github_sponsors:hover, .link-github_sponsors:focus {
  color: #cba6f7;
}

.link-gitlab:hover, .link-gitlab:focus {
  color: #fc6d26;
}

.link-ko-fi:hover, .link-ko-fi:focus {
  color: #29abe0;
}

.link-liberapay:hover, .link-liberapay:focus {
  color: #f6c915;
}

.link-linkedin:hover, .link-linkedin:focus {
  color: #0077b5;
}

.link-paypal:hover, .link-paypal:focus {
  color: #003087;
}

.link-xing:hover, .link-xing:focus {
  color: #026466;
}

.text-spinner {
  font-family: monospace;
}

.scrumplex-logo {
  border-radius: 50%;
  margin-bottom: 10px;
  transition: transform 1s cubic-bezier(.77, 0, .175, 1);
  box-shadow: 0 5px 36px #d63333b3;
}

@media (prefers-color-scheme: dark) {
  .scrumplex-logo {
    box-shadow: 0 5px 30px #d6333366;
  }
}

.wavy {
  background-image: url("wavy.626ec50d.svg");
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;
}

@media (prefers-color-scheme: dark) {
  .wavy {
    background-image: url("wavy-dark.de450cdb.svg");
  }
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-align-center {
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

input {
  color: #000;
  background-color: #0000;
  border: none;
  border-bottom: 2px solid #000;
  border-top-left-radius: 8px;
  outline: none;
  margin: 8px 4px;
  padding: 4px 8px;
  font-size: 1rem;
  transition: background .3s cubic-bezier(.215, .61, .355, 1), border-color .3s cubic-bezier(.215, .61, .355, 1);
}

@media (prefers-color-scheme: dark) {
  input {
    color: #fafafa;
    border-bottom-color: #fafafa;
  }
}

input:hover, input:focus {
  background-color: #0000000d;
}

@media (prefers-color-scheme: dark) {
  input:hover, input:focus {
    background-color: #fafafa0d;
  }
}

input:active {
  background-color: #0003;
}

@media (prefers-color-scheme: dark) {
  input:active {
    background-color: #fafafa33;
  }
}

input:focus {
  border-bottom-color: #d63333;
  outline: none;
}

#ovenplayer {
  --op-accent-color: #d63333;
}

.theater {
  background-color: #1a1a1a;
}

.theater .sheet:after, .theater .sheet:before {
  display: none;
}

.theater .wrapper {
  max-width: initial;
  min-height: initial;
}

.theater #ovenplayer {
  max-height: 70vh;
}

@keyframes panel-fade-in {
  from {
    opacity: 0;
    transform: translateY(100vh);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes panel-splash-fade-in {
  from {
    opacity: 0;
    transform: translateY(100vh);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (width >= 768px) {
  @keyframes panel-fade-in {
    from {
      opacity: 0;
      transform: translateY(300px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes panel-splash-fade-in {
    from {
      opacity: 0;
      transform: translateY(50%);
    }

    to {
      opacity: 1;
      transform: translateY(-50%);
    }
  }
}

@keyframes panel-before-fade-in {
  from {
    background-position-x: -200px;
  }

  to {
    background-position-x: 0;
  }
}

@keyframes panel-after-fade-in {
  from {
    background-position-y: -200px;
  }

  to {
    background-position-y: 0;
  }
}

@keyframes panel-highlight {
  from {
    color: #d63333;
  }

  to {
    color: unset;
  }
}

@keyframes scroll-indicator-fade-in {
  from {
    opacity: 0;
    transform: translateY(-50vh);
  }

  to {
    opacity: 1;
    transform: translateY(-10%);
  }
}

@keyframes float {
  from {
    transform: translateY(-10%);
  }

  to {
    transform: translateY(10%);
  }
}

::-webkit-scrollbar {
  background-color: #b22424;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-sizing: content-box;
  background-color: #b22424;
}

::-webkit-scrollbar-thumb {
  box-sizing: content-box;
  background-color: #fafafa80;
  background-clip: content-box;
  border: none;
  min-height: 30px;
  width: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fafafabf;
}

::-webkit-scrollbar-thumb:active {
  background-color: #fafafa;
}

::-webkit-scrollbar-corner {
  background-color: #b22424;
}

html {
  scrollbar-color: #fafafa #b22424;
  scrollbar-width: thin;
}
/*# sourceMappingURL=index.ffd300ec.css.map */
