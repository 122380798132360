@use "sass:math";
@import "variables";

@mixin col($percent) {
    flex: 0 0 $percent;
    max-width: $percent;
}

.container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: map_get($responsiveThresholds, tiny)) {
        max-width: 540px;
    }
    @media (min-width: map_get($responsiveThresholds, smol)) {
        max-width: 720px;
    }
    @media (min-width: map_get($responsiveThresholds, med)) {
        max-width: 960px;
    }
    @media (min-width: map_get($responsiveThresholds, big)) {
        max-width: 1300px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
}

// Create col-[x]
@for $i from 1 through $gridColumns {
    $percent: math.div(100%, $gridColumns) * $i;

    .col-#{$i} {
        @include col($percent);
    }

    @if $percent != 100% {
        // add padding between columns, but only if it's not a full-width column
        .col-#{$i}:not(:last-child) {
            padding-right: 16px;
        }
    }
}

.col {
    // alias for full-width col
    @extend .col-#{$gridColumns};
}

// Create col-[y]-[x]
@each $key in map_keys($responsiveThresholds) {
    @for $i from 1 through $gridColumns {
        $percent: math.div(100%, $gridColumns) * $i;
        .col-#{$key}-#{$i} {
            @extend .col;
        }

        @media (min-width: map_get($responsiveThresholds, $key)) {
            .col-#{$key}-#{$i} {
                @include col($percent);
            }

            @if $percent != 100% {
                // add padding between columns, but only if it's not a full-width column
                .col-#{$key}-#{$i}:not(:last-child) {
                    padding-right: 16px;
                }
            }
        }
    }
}
