@import "variables";

body {
    background-color: $colorBrand;
    overflow-x: hidden;
}

.wrapper {
    position: relative; // allow child elements to set width to 100%
    min-height: 150vh;
    padding-bottom: 2 * $longShadowLength;
}

.sheet {
    position: relative;
    background-color: $colorBase;
    padding: 24px 24px 8px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow:
        0 10px 20px rgba(0, 0, 0, 0.19),
        0 6px 6px rgba(0, 0, 0, 0.23);

    animation: panel-fade-in 600ms cubic-bezier(0.23, 1, 0.32, 1);

    @media (prefers-color-scheme: dark) {
        background-color: $colorBaseDark;
    }
}

.sheet:target {
    animation:
        panel-fade-in 600ms cubic-bezier(0.23, 1, 0.32, 1),
        panel-highlight 2s linear 600ms;
}

.sheet.sheet-splash,
.sheet.sheet-splashed {
    animation-name: panel-splash-fade-in;
}

@media screen and (min-width: map_get($responsiveThresholds, smol)) {
    .sheet {
        border-top-left-radius: 32px;
        box-shadow: none;
    }

    .sheet.sheet-splash {
        position: absolute;
        top: 50vh;
        transform: translateY(-50%);
    }

    .sheet:before {
        position: absolute;
        top: 0;
        left: 100%;
        width: $longShadowLength;
        height: 100%;
        content: "";
        background: linear-gradient(to right, #481111, transparent) no-repeat;
        transform: skewY(45deg);
        transform-origin: 0 0;
        animation: panel-before-fade-in 900ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    .sheet:after {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: $longShadowLength;
        content: "";
        background: linear-gradient(180deg, #481111, transparent) no-repeat;
        transform: skewX(45deg);
        transform-origin: 0 0;
        animation: panel-after-fade-in 900ms cubic-bezier(0.23, 1, 0.32, 1);
    }
}

// Why is this you ask? Well look into application.js#applyScroll
.scrolled .sheet[hidden] {
    display: block;
    opacity: 0;
    animation: none;
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
}

#particles-js > canvas {
    z-index: -10;
}

#particles-js > .particle-gradient {
    position: absolute;
    bottom: 0;
    height: 20%;
    width: 100%;
    background: linear-gradient(
        to bottom,
        transparentize($colorBrand, 1) 0%,
        $colorBrand 100%
    );
}
