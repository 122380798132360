@font-face {
    font-family: "icons";
    src:
        url("./icons.eot?b71f541e3a0b7a193ebc963c93e9b62e#iefix")
            format("embedded-opentype"),
        url("./icons.woff2?b71f541e3a0b7a193ebc963c93e9b62e") format("woff2"),
        url("./icons.woff?b71f541e3a0b7a193ebc963c93e9b62e") format("woff");
}

i[class^="icon-"]:before,
i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-00-baseline:before {
    content: "\f101";
}
.icon-codeberg:before {
    content: "\f102";
}
.icon-github_sponsors:before {
    content: "\f103";
}
.icon-github:before {
    content: "\f104";
}
.icon-gitlab:before {
    content: "\f105";
}
.icon-ko-fi:before {
    content: "\f106";
}
.icon-liberapay:before {
    content: "\f107";
}
.icon-mail:before {
    content: "\f108";
}
.icon-matrix:before {
    content: "\f109";
}
.icon-new_tab:before {
    content: "\f10a";
}
.icon-paypal:before {
    content: "\f10b";
}
.icon-phone:before {
    content: "\f10c";
}
.icon-teamspeak:before {
    content: "\f10d";
}
.icon-telegram:before {
    content: "\f10e";
}
.icon-wire:before {
    content: "\f10f";
}
